import React from 'react';

import SectionTitle from '../common/SectionTitle';

const FeatureTwo = ({ cardDark }) => {
  return (
    <>
      <section
        className={`feature-section ptb-120 ${
          cardDark ? 'bg-dark' : 'bg-light'
        }`}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              {cardDark ? (
                <SectionTitle
                  subtitle='Dienstleistungen'
                  title='Unsere Dienstleistungen im Überblick'
                  description='Entdecken Sie, wie wir Ihre IT-Bedürfnisse erfüllen'
                  centerAlign
                  dark
                />
              ) : (
                <SectionTitle
                  subtitle='Dienstleistungen'
                  title='Unsere Dienstleistungen im Überblick'
                  description='Entdecken Sie, wie wir Ihre IT-Bedürfnisse erfüllen'
                  centerAlign
                />
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='feature-grid'>
                <div
                  className={`shadow-sm highlight-card rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-primary-soft
                      mb-32
                    '
                  >
                    <i className='fa-solid fa-laptop-code icon-sm text-primary'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Full-Stack Entwicklung</h3>
                    <p>
                      Fullstack-Entwicklung beinhaltet sowohl Frontend-
                      (Benutzeroberfläche) als auch Backend-Entwicklung
                      (Serverseite, Datenbank), was die Erstellung kompletter,
                      funktionsfähiger Anwendungen ermöglicht. Von der
                      Konzeption bis zur finalen Umsetzung sind wir Ihr Partner
                      bei der nahtlosen und effizienten Realisierung Ihrer
                      Projekte.
                    </p>
                    <p>
                      Wir kombinieren Front-End- und Back-End-Fähigkeiten, um
                      individuelle Lösungen für Ihre Projekte zu entwickeln.
                    </p>
                    <h6 className='mt-4'>
                      Branchen, in denen unsere Mitarbeiter tätig waren:
                    </h6>
                    <ul className='list-unstyled mb-0'>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        Automotive
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        Telekommunikation
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        Marketing
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        E-Commerce
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        Bankbranche
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-success-soft
                      mb-32
                    '
                  >
                    <i className='fa-solid fa-code icon-sm text-success'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Front-End Entwicklung</h3>
                    <p className='mb-0'>
                      Wir gestalten ansprechende Benutzeroberflächen mit Hilfe
                      von Vue und React, um eine interaktive und intuitive
                      Nutzererfahrung zu schaffen.
                    </p>
                  </div>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-danger-soft
                      mb-32
                    '
                  >
                    <i className='fa-solid fa-server icon-sm text-danger'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Java Backend Entwicklung</h3>
                    <p className='mb-0'>
                      Vertrauen Sie auf unsere leistungsstarken und sicheren
                      serverseitigen Lösungen, die speziell auf Ihre
                      Geschäftsanforderungen zugeschnitten sind.
                    </p>
                  </div>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-dark-soft
                      mb-32
                    '
                  >
                    <i className='fa-solid fa-network-wired icon-sm text-dark'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>DevOps und Cloud-Lösungen</h3>
                    <p className='mb-0'>
                      Unsere DevOps- und Cloud-Lösungen optimieren Ihre
                      betrieblichen Abläufe und bieten effiziente
                      Cloud-Verwaltung für Ihren Erfolg.
                    </p>
                  </div>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-warning-soft
                      mb-32
                    '
                  >
                    <i className='fa-solid fa-desktop icon-sm text-warning'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Webseitenentwicklung</h3>
                    <p className='mb-0'>
                      Von der Konzeption bis zur Umsetzung entwickeln wir
                      sorgfältig gestaltete Websites, die Ihre Marke
                      widerspiegeln und Ihnen digitalen Erfolg bescheren.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureTwo;

