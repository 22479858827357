import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureOne = () => {
  return (
    <>
      <section className='feature-promo ptb-120 bg-light'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                title='Unsere Werte'
                description='Unsere Werte sind das Fundament unserer Unternehmenskultur und prägen unsere tägliche Arbeit'
                centerAlign
              />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
                <div className='feature-icon icon-center pb-5 rounded-custom bg-primary'>
                  <i className='fa-regular fa-handshake fa-2x text-white'></i>
                </div>
                <div className='feature-info-wrap'>
                  <h3 className='h5'>Kundenorientierung</h3>
                  <p>
                    Wir setzen die Bedürfnisse unserer Kunden an erste Stelle
                    und arbeiten engagiert an ihrer Zufriedenheit.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
                <div className='feature-icon icon-center pb-5 rounded-custom bg-danger'>
                  <i className='fa-regular fa-lightbulb fa-2x text-white'></i>
                </div>
                <div className='feature-info-wrap'>
                  <h3 className='h5'>Innovation</h3>
                  <p>
                    Wir fördern kreative Lösungsansätze und streben nach
                    kontinuierlicher Verbesserung in allem, was wir tun.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
                <div className='feature-icon icon-center pb-5 rounded-custom bg-dark'>
                  <i className='fa-solid fa-user-group fa-2x text-white'></i>
                </div>
                <div className='feature-info-wrap'>
                  <h3 className='h5'>Integrität</h3>
                  <p>
                    Wir handeln stets ehrlich, ethisch und verantwortungsbewusst
                    mit unseren Kunden und Partnern während der ganzen
                    Zusammenarbeit
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureOne;

