import React from 'react';
import { Link } from 'react-router-dom';

const OffCanvasMenu = () => {
  return (
    <>
      <div
        className='offcanvas offcanvas-end'
        tabIndex='-1'
        id='offcanvasWithBackdrop'
      >
        <div className='offcanvas-header d-flex align-items-center mt-4'>
          <Link
            to='/'
            className='d-flex align-items-center mb-md-0 text-decoration-none'
          >
            <div className='navbar-logo'>
              {' '}
              <img
                src='assets/logo/logo_color.png'
                alt='logo'
                className='img-fluid'
              />
            </div>
          </Link>
          <button
            type='button'
            className='close-btn'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          >
            <i className='far fa-close'></i>
          </button>
        </div>
        <div className='offcanvas-body'>
          <ul className='nav col-12 col-md-auto justify-content-center main-menu'>
            <li className='nav-item dropdown'>
              <Link to='/' className='nav-link'>
                Home
              </Link>
            </li>
            <li>
              <Link to='/about-us' className='nav-link'>
                Über uns
              </Link>
            </li>
            <li>
              <Link to='/services' className='nav-link'>
                Dienstleistungen
              </Link>
            </li>
            <li>
              <Link to='/contact-us' className='nav-link'>
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default OffCanvasMenu;
