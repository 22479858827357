import React from 'react';
import PageMeta from '../components/common/PageMeta';
import CtaTwo from '../components/cta/CtaTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import PageHeader from '../components/common/PageHeader';
import FeatureImgSix from '../components/features/FeatureImgSix';
import WorkProcessFour from '../components/work-process/WorkProcessFour';

const About = () => {
  return (
    <Layout>
      <PageMeta title='Über uns - elintech GmbH' />
      <Navbar navDark />
      <PageHeader
        title='Wir sind Experten für IT-Lösungen'
        desc='Bei elintech sind wir Experten für individuelle IT-Lösungen. Unser engagiertes Team ist technologiebegeistert und engagiert sich für Exzellenz. Erfahren Sie mehr darüber, wie wir Ihre IT-Erfahrung auf ein neues Niveau heben können.'
      />
      <FeatureImgSix />
      <WorkProcessFour />
      <CtaTwo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default About;

