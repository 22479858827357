import React from 'react';

import { Link } from 'react-router-dom';

const HeroNine = () => {
  return (
    <section
      className='hero-it-solution hero-nine-bg ptb-120'
      style={{
        background: "url('assets/img/hero-9-img.png')no-repeat",
        backgroundSize: 'cover',
      }}
    >
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6 col-md-10'>
            <div className='hero-content-wrap mt-5 mt-lg-0 mt-xl-0'>
              <h1 className='fw-bold display-5'>Softwareentwicklung</h1>
              <p className='lead'>
                Gemeinsam mit Ihnen entwickeln wir innovative IT-Lösungen, die
                die Zukunft gestalten und Ihr Unternehmen voranbringen
              </p>
              <div className='action-btn mt-5 align-items-center d-block d-sm-flex d-lg-flex d-md-flex'>
                <Link to='/contact-us' className='btn btn-primary me-3'>
                  Kontaktieren Sie uns
                </Link>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='hero-img position-relative mt-5 mt-lg-0'>
              <img
                src='assets/img/banner_image.webp'
                alt='hero hero-it-solution '
                className='img-fluid'
              />
              <div className='dots'>
                <img
                  src='assets/img/banner_dot.png'
                  alt='dot'
                  className='dot-1'
                />
                <img
                  src='assets/img/banner_dot.png'
                  alt='dot'
                  className='dot-2'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroNine;

