import React from 'react';
import PageHeader from '../components/common/PageHeader';
import CtaTwo from '../components/cta/CtaTwo';
import FeatureTwo from '../components/features/FeatureTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import PageMeta from '../components/common/PageMeta';

const Services = () => {
  return (
    <Layout>
      <Navbar navDark />
      <PageMeta title='Leistungen - elintech GmbH' />
      <PageHeader
        title='Dienstleistungen'
        desc='Entdecken Sie unser vielfältiges Angebot an IT-Dienstleistungen'
      />
      <FeatureTwo />

      <CtaTwo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default Services;

