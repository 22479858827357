import React from 'react';
import Layout from '../layout/Layout';
import Navbar from '../layout/Header/Navbar';
import PageHeader from '../components/common/PageHeader';
import FooterOne from '../layout/Footer/FooterOne';
import AgbContent from '../components/agb/AgbContent';

const Agb = () => {
  return (
    <>
      <Layout>
        <Navbar navDark />
        <PageHeader title='Allgemeine Geschäftsbedingungen' />
        <AgbContent />
        <FooterOne footerLight />
      </Layout>
    </>
  );
};

export default Agb;

