import React from 'react';
import Layout from '../layout/Layout';
import Navbar from '../layout/Header/Navbar';
import PageHeader from '../components/common/PageHeader';
import FooterOne from '../layout/Footer/FooterOne';
import ImpressumContent from '../components/impressum/ImpressumContent';

const Impressum = () => {
  return (
    <>
      <Layout>
        <Navbar navDark />
        <PageHeader title="Impressum" />
        <ImpressumContent />
        <FooterOne footerLight />
      </Layout>
    </>
  );
};

export default Impressum;
