import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../common/SectionTitle';

const CtaTwo = () => {
  return (
    <>
      <section className='cta-subscribe bg-dark ptb-120 position-relative overflow-hidden'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-10'>
              <div className='subscribe-info-wrap text-center position-relative z-2'>
                <SectionTitle
                  title='Kontaktieren Sie uns'
                  description='Wir sind hier, um Ihnen zu helfen. Ob Sie Fragen haben, weitere Informationen benötigen oder Ihre IT-Anforderungen besprechen möchten, unser Team steht Ihnen gerne zur Verfügung. Kontaktieren Sie uns noch heute, und lassen Sie uns darüber sprechen, wie wir Ihr Unternehmen unterstützen können."'
                  dark
                />
                <div className='form-block-banner mw-60 m-auto mt-5'>
                  <Link to='/contact-us' className='btn btn-primary'>
                    Kontakt aufnehmen
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className='
              bg-circle
              rounded-circle
              circle-shape-3
              position-absolute
              bg-dark-light
              left-5
            '
          ></div>
          <div
            className='
              bg-circle
              rounded-circle
              circle-shape-1
              position-absolute
              bg-warning
              right-5
            '
          ></div>
        </div>
      </section>
    </>
  );
};

export default CtaTwo;

