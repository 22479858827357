import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FaqTwo = () => {
  return (
    <>
      <section className='faq-section ptb-120 bg-light'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12'>
              <SectionTitle
                subtitle='FAQ'
                title='Häufig gestellte Fragen'
                description='Willkommen in unserer FAQ-Sektion, in der wir häufig gestellte Fragen zu unseren IT-Dienstleistungen und unserem Unternehmen beantworten.'
                centerAlign
              />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12'>
              <div className='accordion faq-accordion' id='accordionExample'>
                <div className='accordion-item border border-2 '>
                  <h5 className='accordion-header' id='faq-1'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-1'
                      aria-expanded='true'
                    >
                      Wie kann ich eine Beratung anfordern?
                    </button>
                  </h5>
                  <div
                    id='collapse-1'
                    className='accordion-collapse collapse show'
                    aria-labelledby='faq-1'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Um eine Beratung anzufordern, besuchen Sie bitte den
                      'Kontakt'-Bereich auf unserer Website. Dort können Sie
                      Ihre bevorzugte Kontaktmethode auswählen, sei es über
                      unser Kontaktformular, per E-Mail oder Telefon. Unser Team
                      wird sich umgehend bei Ihnen melden, um ein Treffen nach
                      Ihren Wünschen zu vereinbaren.
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-2'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-2'
                      aria-expanded='false'
                    >
                      In welchen Branchen sind Sie tätig?
                    </button>
                  </h5>
                  <div
                    id='collapse-2'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-2'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Wir bieten IT-Lösungen in verschiedenen Branchen an.
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-3'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-3'
                      aria-expanded='false'
                    >
                      Sind Ihre Dienstleistungen für kleine Unternehmen
                      skalierbar?
                    </button>
                  </h5>
                  <div
                    id='collapse-3'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-3'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Absolut. Wir bieten skalierbare IT-Lösungen, die auf
                      Unternehmen jeder Größe zugeschnitten sind. Ob Sie ein
                      Start-up oder ein Großunternehmen sind, wir können unsere
                      Dienstleistungen an Ihre Bedürfnisse anpassen.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqTwo;

