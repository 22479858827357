import React from 'react';

const AgbContent = () => {
  return (
    <>
      <section className='impressum-section ptb-120'>
        <div className='container'>
          <ul className='list-unstyled mb-0'>
            <li className='d-flex align-items-start mb-4'>
              <div className='icon-content'>
                <p>
                  Die AGB regeln die Bedingungen der elintech GmbH. Sie umfassen
                  IT-Dienstleistungen, Unternehmensberatung sowie Erstellung,
                  Betrieb und Vertrieb von Softwarelösungen und digitalen
                  Produkten. Erlaubnispflichtige Geschäfte werden nicht
                  betrieben. Die AGB legen Vertragsdetails, Zahlung, Haftung und
                  Datenschutz fest. Ziel ist eine klare und transparente
                  Zusammenarbeit.
                </p>
              </div>
            </li>
            <li className='d-flex align-items-start mb-4'>
              <div className='icon-content'>
                <h3 className='h4'>I. Geltungsbereich</h3>
                <p>
                  Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für
                  sämtliche Leistungen und Verträge zwischen elintech GmbH
                  (nachfolgend "Agentur" genannt), und ihren Kunden (nachfolgend
                  "Kunde" genannt). Abweichende Bedingungen des Kunden werden
                  nicht anerkannt, es sei denn, die Agentur stimmt ihrer Geltung
                  ausdrücklich schriftlich zu.
                </p>
              </div>
            </li>
            <li className='d-flex align-items-start mb-4'>
              <div className='icon-content'>
                <h3 className='h4'>II. Leistungsbeschreibung</h3>
                <p>
                  Die Agentur bietet Dienstleistungen im Bereich
                  Webseitenentwicklung, Branding und Digital Marketing
                  Strategien an. Die genaue Leistungsbeschreibung, der Umfang
                  der Dienstleistungen sowie die Vergütung werden in einem
                  individuellen Vertrag oder Angebot festgelegt.
                </p>
              </div>
            </li>
            <li className='d-flex align-items-start mb-4'>
              <div className='icon-content'>
                <h3 className='h4'>III. Vertragsabschluss und Angebote</h3>
                <p>
                  Die Angebote der Agentur sind freibleibend und unverbindlich.
                  Ein Vertrag kommt erst durch eine schriftliche
                  Auftragsbestätigung oder eine schriftliche Vereinbarung
                  zwischen der Agentur und dem Kunden zustande.
                </p>
              </div>
            </li>
            <li className='d-flex align-items-start mb-4'>
              <div className='icon-content'>
                <h3 className='h4'>IV. Vergütung und Zahlungsbedingungen</h3>
                <p>
                  Die Vergütung für die erbrachten Leistungen wird in dem
                  individuellen Vertrag oder Angebot festgelegt. Sofern nichts
                  anderes vereinbart wurde, sind alle Zahlungen innerhalb von 14
                  Tagen ab Rechnungsdatum ohne Abzüge fällig. Bei Projekten mit
                  längerer Laufzeit behält sich die Agentur das Recht vor,
                  Teilzahlungen zu vereinbaren.
                </p>
              </div>
            </li>
            <li className='d-flex align-items-start mb-4'>
              <div className='icon-content'>
                <h3 className='h4'>V. Pflichten des Kunden</h3>
                <p>
                  Der Kunde verpflichtet sich, alle für die Auftragsdurchführung
                  notwendigen Informationen und Materialien rechtzeitig und in
                  digitaler Form zur Verfügung zu stellen. Zudem ist der Kunde
                  dafür verantwortlich, sämtliche erforderlichen Rechte an den
                  bereitgestellten Inhalten zu besitzen oder entsprechende
                  Nutzungsrechte einzuholen.
                </p>
              </div>
            </li>
            <li className='d-flex align-items-start mb-4'>
              <div className='icon-content'>
                <h3 className='h4'>VI. Haftung und Gewährleistung</h3>
                <p>
                  Die Agentur haftet nur für Vorsatz und grobe Fahrlässigkeit.
                  Die Haftung für leichte Fahrlässigkeit ist ausgeschlossen, es
                  sei denn, es handelt sich um eine Verletzung wesentlicher
                  Vertragspflichten. Die Gewährleistungsfrist beträgt sechs
                  Monate ab Abnahme der Leistungen.
                </p>
              </div>
            </li>
            <li className='d-flex align-items-start mb-4'>
              <div className='icon-content'>
                <h3 className='h4'>VII. Datenschutz</h3>
                <p>
                  Die Agentur verpflichtet sich, die geltenden
                  Datenschutzbestimmungen einzuhalten und alle personenbezogenen
                  Daten vertraulich zu behandeln.
                </p>
              </div>
            </li>
            <li className='d-flex align-items-start mb-4'>
              <div className='icon-content'>
                <h3 className='h4'>VIII. Geheimhaltung</h3>
                <p>
                  Die Agentur und der Kunde verpflichten sich zur gegenseitigen
                  Geheimhaltung aller nicht öffentlich zugänglichen
                  Informationen und Daten, die im Rahmen der Zusammenarbeit
                  bekannt werden.
                </p>
              </div>
            </li>
            <li className='d-flex align-items-start mb-4'>
              <div className='icon-content'>
                <h3 className='h4'>IX. Vertragsdauer und Kündigung</h3>
                <p>
                  Sofern nichts anderes vereinbart wurde, wird der Vertrag auf
                  unbestimmte Zeit geschlossen. Beide Vertragsparteien können
                  den Vertrag mit einer Frist von 30 Tagen schriftlich kündigen.
                </p>
              </div>
            </li>
            <li className='d-flex align-items-start mb-4'>
              <div className='icon-content'>
                <h3 className='h4'>X. Schlussbestimmungen</h3>
                <p>
                  Es gilt das Recht der Bundesrepublik Deutschland.
                  Erfüllungsort und Gerichtsstand ist der Sitz der Agentur.
                  Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder
                  werden, bleibt die Wirksamkeit der übrigen Bestimmungen
                  unberührt. Anstelle der unwirksamen Bestimmungen gilt eine
                  wirksame Bestimmung, die dem wirtschaftlichen Sinn und Zweck
                  der unwirksamen Bestimmung am nächsten kommt.
                </p>
              </div>
            </li>
            <li className='d-flex align-items-start mb-4'>
              <div className='icon-content'>
                <h3 className='h5'>Diese AGB sind ohne Unterschrift gültig.</h3>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default AgbContent;

