import React from 'react';

const WorkProcessFour = () => {
  return (
    <>
      <section className='work-process ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-6'>
              <div className='section-heading text-center'>
                <h4 className='h5 text-primary'>Agilität in der Praxis</h4>
                <h2>Unsere Agile Arbeitsweise</h2>
                <p>
                  Wir setzen auf Agile Methoden, um unsere Arbeit effizient und
                  kundenorientiert zu gestalten
                </p>
              </div>
            </div>
          </div>
          <div className='row d-flex align-items-center'>
            <div className='col-md-6 col-lg-3'>
              <div className='process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-2 mb-lg-0'>
                <div className='process-icon border border-light bg-custom-light rounded-custom p-3'>
                  <span className='h2 mb-0 text-primary fw-bold'>1</span>
                </div>
                <h3 className='h5'>Flexibilität </h3>
                <p className='mb-0'>
                  Anpassungsfähig bei sich ändernden Anforderungen.
                </p>
              </div>
            </div>
            <div className='dots-line first'></div>
            <div className='col-md-6 col-lg-3'>
              <div className='process-card text-center px-4 py-lg-5 py-3 rounded-custom shadow-hover mb-2 mb-lg-0'>
                <div className='process-icon border border-light bg-custom-light rounded-custom p-3'>
                  <span className='h2 mb-0 text-primary fw-bold'>2</span>
                </div>
                <h3 className='h5'>Kundenzentriertheit</h3>
                <p className='mb-0'>
                  Fokus auf die Bedürfnisse und Zufriedenheit unserer Kunden.
                </p>
              </div>
            </div>
            <div className='dots-line first'></div>
            <div className='col-md-6 col-lg-3'>
              <div className='process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-2 mb-lg-0 mb-md-0'>
                <div className='process-icon border border-light bg-custom-light rounded-custom p-3'>
                  <span className='h2 mb-0 text-primary fw-bold'>3</span>
                </div>
                <h3 className='h5'>Inkrementell </h3>
                <p className='mb-0'>
                  Schrittweise Fortschritte für schnellere Ergebnisse.
                </p>
              </div>
            </div>
            <div className='dots-line first'></div>
            <div className='col-md-6 col-lg-3'>
              <div className='process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-0 mb-lg-0 mb-md-0'>
                <div className='process-icon border border-light bg-custom-light rounded-custom p-3'>
                  <span className='h2 mb-0 text-primary fw-bold'>4</span>
                </div>
                <h3 className='h5'>Verbesserung </h3>
                <p className='mb-0'>
                  Regelmäßige Anpassungen für optimale Leistung.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkProcessFour;

