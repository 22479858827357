import React from 'react';
import { Link } from 'react-router-dom';

const AboutImage = () => {
  return (
    <section className='ptb-120'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6'>
            <div className='about-left text-lg-center mb-32 mb-lg-0'>
              <img src='assets/img/about.webp' alt='' className='img-fluid' />
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='about-right'>
              <h2 className='mb-4'>
                Entdecken Sie Neue Horizonte in IT-Lösungen
              </h2>
              <p>
                Erkunden Sie das aufregende Potenzial unseres kundenorientierten
                Ansatzes für IT-Lösungen.
              </p>
              <ul className='list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4'>
                <li className='py-1'>
                  <div className='d-flex about-icon-box align-items-center'>
                    <div className='me-3'>
                      <img src='assets/img/pie-chart.png' alt='' />
                    </div>
                    <div>
                      <h5>Fachwissen und Erfahrung</h5>
                    </div>
                  </div>
                </li>
                <li className='py-1'>
                  <div className='d-flex about-icon-box align-items-center'>
                    <div className='me-3'>
                      <img src='assets/img/team.png' alt='' />
                    </div>
                    <div>
                      <h5>Kundenorientierter Ansatz</h5>
                    </div>
                  </div>
                </li>
              </ul>

              <ul className='list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4'>
                <li className='py-1'>
                  <i className='fad fa-check-circle me-2 text-primary'></i>
                  Umfassendes Branchenwissen
                </li>
                <li className='py-1'>
                  <i className='fad fa-check-circle me-2 text-primary'></i>
                  Individuelle Lösungen
                </li>
                <li className='py-1'>
                  <i className='fad fa-check-circle me-2 text-primary'></i>
                  Spitzentechnologie
                </li>
                <li className='py-1'>
                  <i className='fad fa-check-circle me-2 text-primary'></i>
                  Transparente Kommunikation
                </li>
                <li className='py-1'>
                  <i className='fad fa-check-circle me-2 text-primary'></i>
                  Kosteneffizienz
                </li>
                <li className='py-1'>
                  <i className='fad fa-check-circle me-2 text-primary'></i>
                  Langfristige Partnerschaften
                </li>
              </ul>
              <Link
                to='/about-us'
                className='link-with-icon text-decoration-none mt-3 btn btn-primary'
              >
                Mehr erfahren
                <i className='fas fa-arrow-right'></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutImage;

