import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureImgSix = () => {
  return (
    <>
      <section className='feature-section-two ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between justify-content-center'>
            <div className='col-lg-6 col-md-12'>
              <div className='feature-content-wrap'>
                <SectionTitle
                  title='Unsere IT-Dienstleistungen'
                  description=' Erfahren Sie, wie unsere Expertise Ihr Unternehmen stärken kann. Wir bieten eine breite Palette von IT-Dienstleistungen, um Ihre individuellen Bedürfnisse und Herausforderungen zu bewältigen.'
                  leftAlign
                />
                <ul className='list-unstyled mb-0'>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-primary rounded me-4'>
                      <i className='fas fa-bezier-curve text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>IT-Beratung</h3>
                      <p>
                        Unsere erfahrenen Berater bieten strategische
                        Unterstützung, um Ihre IT mit Ihren Geschäftszielen in
                        Einklang zu bringen.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-danger rounded me-4'>
                      <i className='fal fa-code fa-2x text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Softwareentwicklung</h3>
                      <p>
                        Wir entwerfen und entwickeln individuelle
                        Softwarelösungen, die Ihren speziellen Anforderungen
                        entsprechen.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4 mb-lg-0'>
                    <div className='icon-box bg-dark rounded me-4'>
                      <i className='fal fa-shield-check fa-2x text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Recruiting</h3>
                      <p>
                        Bitte übersenden Sie eine Beschreibung Ihres Projekts an
                        info@elintech.de. Wir werden daraufhin schnellstmöglich
                        mit Ihnen in Verbindung treten. In einem gemeinsamen
                        Gespräch erörtern wir Ihre spezifischen Anliegen und
                        beginnen anschließend mit der Rekrutierung einer
                        IT-Fachkraft, die ideal Ihren Bedürfnisse entsprechen
                        wird.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/feature-hero-img.svg'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgSix;

