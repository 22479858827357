import React from 'react';
import Routers from './routers';

// Swiper CSS
import 'swiper/css';
import 'swiper/css/navigation';

//bootstrap
import 'bootstrap/dist/js/bootstrap.bundle';

//Modal Video
import 'react-modal-video/scss/modal-video.scss';

function App() {
  return (
    <>
      <Routers />
    </>
  );
}

export default App;

