import React from 'react';
import Layout from '../layout/Layout';
import Navbar from '../layout/Header/Navbar';
import PageHeader from '../components/common/PageHeader';
import FooterOne from '../layout/Footer/FooterOne';
import DataProtectionContent from '../components/data-protection/DataProtectionContent';

const DataProtection = () => {
  return (
    <>
      <Layout>
        <Navbar navDark />
        <PageHeader title='Datenschutz­erklärung' />
        <DataProtectionContent />
        <FooterOne footerLight />
      </Layout>
    </>
  );
};

export default DataProtection;

