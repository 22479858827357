import React from 'react';
import { Link } from 'react-router-dom';

const FooterOne = ({ footerLight, style, footerGradient }) => {
  return (
    <>
      <footer className='footer-section'>
        <div
          className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${
            footerGradient ? 'bg-gradient' : ''
          }  text-white ptb-120`}
          style={style}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-8 col-lg-4 mb-md-4 mb-lg-0'>
                <div className='footer-single-col'>
                  <div className='footer-single-col mb-4'>
                    <div className='footer-logo'>
                      {footerLight ? (
                        <img
                          src='assets/logo/logo_color.png'
                          alt='logo'
                          className='img-fluid'
                        />
                      ) : (
                        <img
                          src='assets/logo/logo_white.png'
                          alt='logo'
                          className='img-fluid'
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0'>
                <div className='row'>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link
                            to='/impressum'
                            className='text-decoration-none'
                          >
                            Impressum
                          </Link>
                        </li>
                        <li>
                          <Link to='/agb' className='text-decoration-none'>
                            AGB
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/data-protection'
                            className='text-decoration-none'
                          >
                            Datenschutz­erklärung
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className='col col-md-7 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <ul className='list-unstyled footer-nav-list contact mb-lg-0'>
                        <li className='row align-items-center'>
                          <span className='fad fa-location-dot fa-sharp fa-solid fa-2x text-primary col-auto mb-2'></span>
                          <h6 className='col'>
                            Lindleystraße 8a, <br /> 60314 Frankfurt am Main
                          </h6>
                        </li>

                        <li className='row align-items-center'>
                          <span className='fad fa-envelope fa-2x text-primary col-auto mb-2'></span>
                          <h6 className='col footer-email'>
                            {' '}
                            <a href='mailto:info@elintech.de'>
                              info@elintech.de
                            </a>{' '}
                          </h6>
                        </li>
                        <li className='row align-items-center'>
                          <span className='fad fa-phone fa-2x text-primary col-auto mb-2'></span>

                          <h6 className='col'>
                            <a href='tel:+4917624224368'>+49 17624224368</a>
                          </h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`footer-bottom ${
            footerLight ? 'footer-light' : 'bg-dark'
          } ${footerGradient ? 'bg-gradient' : ''} text-white py-4`}
        >
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                    &copy; 2024 elintech GmbH. Alle Rechte vorbehalten
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;

